import request from '@/api/request.js'


// 设备分页
export function getDevice (data) {
  return request({
    url: '/api/device/page',
    method: 'POST',
    data
  })
}
// 查询设备
export function getDeviceList (data) {
  return request({
    url: '/api/device/list',
    method: 'POST',
    data
  })
}

// 获取所有设备型号
export function getDevModels (data) {
  return request({
    url: '/api/device/models',
    method: 'POST',
    data
  })
}

// 批量打印商品条码
export function getBatchPrintSku (data) {
  return request({
    url: '/api/device/batchPrintSku',
    method: 'POST',
    data
  })
}

// 打印商品模板
export function getPointTemplates (data) {
  return request({
    url: '/api/device/templates',
    method: 'POST',
    data
  })
}


// 添加设备
export function addDevice (data) {
  return request({
    url: '/api/device/add',
    method: 'POST',
    data
  })
}

// 编辑设备
export function editDevice (data) {
  return request({
    url: '/api/device/edit',
    method: 'POST',
    data
  })
}

// 删除设备
export function delDevice (data) {
  return request({
    url: '/api/device/delete',
    method: 'POST',
    data
  })
}
 