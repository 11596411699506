<template>
  <div>
    <div class="contents">
      <div class="tableSearch">
          <div class="classObj"><el-button @click="shouquan" type="primary" icon="el-icon-plus">添加设备</el-button></div>
          <el-form :inline="true" ref="searchForm" :model="searchForm" label-width="80px">
             <el-form-item label="" style="margin-bottom:0px">
              <el-input v-model="searchForm.name" clearable  style="width:190px"
                placeholder="请输入设备名称"></el-input>
            </el-form-item>             
            <el-button @click="getTopSearch" type="primary" icon="el-icon-search">查询</el-button>
          </el-form>
      </div>

    <div class="mainbox">
       <el-table
        :data="tableData"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          type="index"
          width="40" header-align="center" align="center">
        </el-table-column>
        <el-table-column
          prop="name"
          label="设备名称"
          >
        </el-table-column>
         <el-table-column
          prop="code"
          label="设备编号"
          width="150">
        </el-table-column>      
        <el-table-column
          label="设备状态"
          width="100">
          <template slot-scope="scope">
            {{scope.row.status ==1?'正常':'未知状态'}}
          </template>
        </el-table-column>
         <el-table-column
          label="设备类型"
          >
          <template slot-scope="scope">
            {{scope.row.type ==1?'打印机':'其他'}}
          </template>
        </el-table-column>
       <el-table-column
          prop="createTime"
          label="创建时间"
          >
        </el-table-column>
       <el-table-column
      fixed="right"
      label="操作"
      width="160" header-align="center" align="center">
      <template slot-scope="scope">
        <el-button @click="editFun(scope.row)" type="text" size="small">编辑</el-button>
        <el-popconfirm @confirm="delChannel(scope.row)"
          title="确定要删除吗？"
          style="margin-left: 10px;"
        >
        <template #reference>
          <el-button type="text">删除</el-button>
          </template>
       </el-popconfirm>
      </template>
    </el-table-column>
      </el-table>
      <div class="paginations">
          <el-pagination
          background
          layout="total, prev, pager, next, sizes"
          @current-change="changePage" :current-page="page" :page-size="pageSize"
          :page-sizes="[20, 40, 50, 100]"
          :total="total">
        </el-pagination>
      </div>
      </div>
    </div>

    <!-- <el-dialog title="所支持的设备型号" :visible.sync="yaoflag" :before-close="downClose" width="660px">
        <div class="fenxiaoBox">
           <el-table
        :data="devModel"
        border
        :header-cell-style="{background:'#f7f7f7', color:'#666'}" stripe
        style="width: 100%">
        <el-table-column
          prop="brand"
          label="品牌"
          >
        </el-table-column>
         <el-table-column
          prop="model"
          label="型号"
          width="150">
        </el-table-column>      
        <el-table-column
          prop="name"
          label="名称"
          width="150">
        </el-table-column>  
         <el-table-column
          label="设备类型"
          >
          <template slot-scope="scope">
            {{scope.row.type ==0?'打印机':'其他'}}
          </template>
        </el-table-column>
           </el-table>
        </div>
        <div class="midCss" style="padding-top:20px">
          <el-button type="primary" @click="handleSubmit">确认</el-button>
          <el-button @click="downClose">取 消</el-button>
        </div>
      </el-dialog> -->


      <el-dialog :title="titles" :visible.sync="qunVisible" :before-close="handleClose" width="640px">
      <el-form ref="formAdd" :model="formAdd" label-position="right" label-width="120px">
         <el-form-item label="设备名称" prop="name">
          <el-input v-model="formAdd.name" maxlength="30" placeholder="请输入设备名称"></el-input>
        </el-form-item>
        <el-form-item label="设备编号" prop="code">
          <el-input v-model="formAdd.code" maxlength="30" placeholder="请输入设备编号"></el-input>
        </el-form-item>
         <el-form-item label="设备型号" prop="config">
            <el-select v-model="formAdd.model" clearable placeholder="请选择" @change="qunClicks">
              <el-option
                v-for="item in devModel"
                :key="item.model"
                :label="item.name"
                :value="item.model">
              </el-option>
            </el-select>
        </el-form-item>
       
       
      </el-form>
        <div class="midCss">
          <el-button @click="handleClose" size="medium">取 消</el-button>
          <el-button type="primary" @click="addDevices" size="medium">提交</el-button>
        </div>
       </el-dialog>
  </div>
</template>

<script>
import { getDevice, getDevModels,addDevice, delDevice, editDevice } from "@/api/device";
import {
  MessageBox
} from 'element-ui'
export default {
  name: 'DeviceMain',
  data() {
    return {
      searchForm: {
        name: '',
        groupName: ''
      },
      formAdd: {
        id: '',
        code: '',
        model: '',
        name: '',
      },
      total: 0,
      page: 1,
      pageSize: 20,
      shopflag: false,
      qunvalue: '',
      qunid: '', 
      shopArr: [],
      devModel: [],
      qunoptions: [],
      loadingFile: false,
      titles: '新增设备',
      yaoflag: false,
      qunVisible: false,
      tableData: [],
      multipleSelection: []
    }
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleClose(){
      this.qunVisible = false;
    },
    qunClicks(e){
      console.log(e)
    },
    getTopSearch(e){
      var that = this
      var datas = that.searchForm
      datas.pageNo = that.page;
      datas.pageSize = that.pageSize;
      datas.orderByCreated = "DESC";
      getDevice(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
          that.tableData = res.data.rows
          that.total = res.data.totalRows
        }else{
          this.$message.error(res.message)
        }
      })
    },
    changePage (newPage) {
      var that = this
      if (that.page === newPage) {
        return
      }
      that.page = newPage
      that.getTopSearch()
    },
    editFun(row){
      var that = this
      that.qunVisible = true
      that.titles = '编辑设备'
      that.formAdd.id = row.id
      that.formAdd.name = row.name
      that.formAdd.code = row.code
      that.formAdd.model = row.model
      that.formAdd.config = row.config
      that.getDeviceModel()
    },
    addDevices(){
      var that = this
      var datas = that.formAdd
      if(datas.id){
        editDevice(datas).then(res => {
        if(res.code ===200) {
         that.$message({
              message: '编辑成功',
              type: 'success'
            })
            that.qunVisible = false
            that.getTopSearch()
        }else{
          this.$message.error(res.message)
        }
      })

      }else{
      delete datas['id']
      addDevice(datas).then(res => {
        console.log(res);
        if(res.code ===200) {
         that.$message({
              message: '添加成功',
              type: 'success'
            })
            that.qunVisible = false
            that.getTopSearch()
        }else{
          this.$message.error(res.message)
        }
      })
      }
    },
    downClose(){
      var that = this
      that.yaoflag = false
    },
   
    delChannel(row){
       var that = this
       var datas = {
         id: row.id,
       }
       delDevice(datas).then(res => {
         if(res.code === 200) {
            that.$message({
              message: '删除成功',
              type: 'success'
            })
            that.getTopSearch()
         }else{
           this.$message.error(res.message)
         }
       })
    },
    getDeviceModel(){
       var that = this
       getDevModels().then(res => {
         if(res.code === 200) {
           that.devModel = res.data
         }else{
           this.$message.error(res.message)
         }
       })
    },
    shouquan(){
      var that = this
      that.qunVisible = true
       that.formAdd.id = ''
      that.formAdd.name = ''
      that.formAdd.code = ''
      that.formAdd.model = ''
      that.getDeviceModel()
    }
  },
  mounted(){
    this.getTopSearch()
  }
}
</script>

<style scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: #07C160;
  color: #FFF;
}
.el-tabs__nav-wrap::after{
  height: 1px;
}
.el-form-item__label{
  font-size: 12px;
}

.el-button--primary {
  color: #FFF;
  background-color: #07C160;
  border-color: #07C160;
}
.classObj{
  float:right;
  font-size: 12px;
  color: #999;
  cursor: pointer;
}
.midCss {
  text-align: right;
  display: block;
  padding-top: 20px;
}
</style>